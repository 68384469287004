import $ from 'jquery';

var sub_arr = [];
var prod_all = [];

export default class Production {
  constructor(app) {
    this.app = app;
    if (this.app.hash.indexOf('sub') == -1) {
      // window.location.replace('#sub1');
    }
    this.buildSection();
    this.getData();
  }

  buildIndex() {
    var prod_nav = "";
    var prod_sub = "";
    for(var i = 0; i<this.app.nav_arr.data.length;i++){
      if($.inArray(this.app.nav_arr.data[i].id,this.app.prod_sub)!==-1){
        prod_nav+= '<a class="prodIndex__item" href="#sub'+this.app.nav_arr.data[i].id+'">'+this.app.nav_arr.data[i].name+'</a>'
        prod_sub+= '<div class="submenu__item sub__nav__item">'+
                      '<a class="suba" href="production.html#sub'+this.app.nav_arr.data[i].id+'">'+this.app.nav_arr.data[i].name+'</a>'+
                    '</div>'
      }
    }
    // console.log(this.app.prod_sub);
    $('.production__section').append('' +
      '<section class="production-index page-content index">' +
      '<div class="container">' +
        '<div class="prodIndex">'+
          prod_nav+
        '</div>'+
      '</div>' +
      '</section>'
    );
    $('.submenu').append(prod_sub);
    this.setSubMenuSlider();
  }
  setSubMenuSlider(){
    if($('.submenu').hasClass('slick-initialized')){
      $('.submenu').slick('unslick');
    }
    $('.submenu').slick({
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false
    })
  }
  buildSection() {
    this.buildIndex();
    for (var i = 0; i < this.app.nav_arr.data.length; i++) {
      $('.production__section').append('' +
        '<section class="production-index page-content sub' + this.app.nav_arr.data[i].id + '">' +
        '<div class="container">' +
        '<div class="btn-box row">' +
        '</div>' +
        '<div class="production__box row sub__box">' +
        '</div>' +
        '</div>' +
        '</section>'
      )
    }
  }
  getData() {
    var prod_arr;
    var prod_xhr = new XMLHttpRequest();
    if (this.app.testMode == true) {
      prod_xhr.open('get', './api/products.json', true);
    } else {
      prod_xhr.open('get', this.app.host + 'api/products', true);
    }
    prod_xhr.send();
    prod_xhr.onload = function() {
      if (prod_xhr.status == 200) {
        // online
        prod_arr = JSON.parse(prod_xhr.responseText);
        prod_all = prod_arr.data;
        for (var i = 0; i < prod_arr.data.length; i++) {
          if (prod_arr.data[i].sub_category !== null) {
            //has sub categorys
            var tempArr = [].concat(...sub_arr);
            if ($.inArray(prod_arr.data[i].subCategoryName, tempArr) == -1) {
              sub_arr.push(new Array(prod_arr.data[i].category, prod_arr.data[i].sub_category, prod_arr.data[i].subCategoryName));
            }
          } else {
            //no sub categorys

          }
          $('.sub' + prod_arr.data[i].category + ' .production__box').append('' +
            '<div class="col-6 col-md-4 col-lg-3 production-index__box item__box" data-type="' + prod_arr.data[i].sub_category + '">' +
            '<div class="item" data-id="' + prod_arr.data[i].id + '">' +
            '<div class="item__img">' +
            '<img src="' + this.app.host + prod_arr.data[i].medias[0].path + '" />' +
            '</div>' +
            '<div class="item__info">' +
            '<span class="item__info__num text-center">' + prod_arr.data[i].title + '</span>' +
            '<span class="item__info__price text-center">$' + prod_arr.data[i].price + '</span>' +
            '</div>' +
            '</div>' +
            '</div>'
          )
        }
        this.init();
      } else {

      }
    }.bind(this)
  }
  buildPage() {
    for (var i = 0; i < sub_arr.length; i++) {
      $('.sub' + sub_arr[i][0] + ' .btn-box').append(
        '<div class="col-6 production-index__button__box">' +
        '<div class="production-index__button btn-1 sub_btn" data-sub="' + sub_arr[i][1] + '">' +
        '' + sub_arr[i][2] +
        '</div>' +
        '</div>'
      )
    }
  }
  init() {
    this.buildPage();
    this.setSlider();
    $('.sub_btn').click(function() {

      //
      $('.sub_btn').removeClass('is-active');
      $(this).addClass('is-active');
      // console.log($(this).data("sub"));
      $('.sub__box .item__box').hide();
      $('.sub__box .item__box[data-type=' + $(this).data("sub") + ']').show();
    })
    $("span.item__info__num").each(function() {
      // console.log($(this));
      var maxwidth = 8; //設置最多顯示的字數
      var text = $(this).text();
      if ($(this).text().length > maxwidth) {
        $(this).text($(this).text().substring(0, maxwidth));
        $(this).html($(this).html() + "...");
      };
    })
    var hostAddr = this.app.host;
    $('.item[data-id]').click(function() {
      var num;

      // console.log($(this).data('id'));
      num = "" + $(this).data("id");
      var prod_arr;
      var prod_xhr = new XMLHttpRequest();
      prod_xhr.open('get', hostAddr + 'api/products/' + num, true);
      prod_xhr.send();
      prod_xhr.onload = function() {
        if (prod_xhr.status == 200) {
          prod_arr = JSON.parse(prod_xhr.responseText);
          // console.log(prod_arr);
          $('#prodModal .modal-title').html(prod_arr.data[0].title);
          var imgPath = " ";
          for (var j = 0; j < prod_arr.data[0].medias.length; j++) {
            imgPath += '<img src="' + hostAddr + prod_arr.data[0].medias[j].path + '"/>'
          }
          // console.log(imgPath);
          $('#prodModal .img__slider').html('' + imgPath);
          // $('#prodModal .img__slider').slick('setPosition');
          $('#prodModal .content').html(prod_arr.data[0].content_desc);
          $('#prodModal').modal('show')
        }
      }

    })
  }

  setSlider() {
    $('.img__slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: false,
      nextArrow: false,
      dots: true
    })
  }
  refreshSlder() {
    $('.img__slider').slick('setPosition');

  }
  resizing(){
    this.setSubMenuSlider();
  }
  hashchange() {
    this.refreshSlder();
    $('.item__box').show();
  }
}
