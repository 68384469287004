import ready from 'domready';
import App from './App';
import $ from 'jquery';
import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

ready(() => {
	window.app = new App();
	window.app.init();
});
