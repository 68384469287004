import $ from 'jquery';

var news_ready = false;
var pro_ready = false;
export default class IndexContent {
  constructor(app) {
    this.app = app;
    this.getKV();
    this.getNewsData();
    this.getProductionData();

    // this.init();
  }
  getKV() {
    var kv_arr;
    var kv_xhr = new XMLHttpRequest();
    kv_xhr.open('get', this.app.host + 'api/kvs', true);
    kv_xhr.send();
    kv_xhr.onload = function() {
      if (kv_xhr.status == 200) {
        kv_arr = JSON.parse(kv_xhr.responseText);
        for (var i = 0; i < kv_arr.data.length; i++) {
          $('.kv-slider').append('' +
            '<div class="kv-slider__item">' +
            '<div class="kv-slider__item__container">' +
            '<a  href="' + kv_arr.data[i].url + '" target="_blank" >' +
            '<img src="' + this.app.host + kv_arr.data[i].img_path + '" />' +
            '</a>' +
            '</div>' +
            '</div>'
          )

        }
      }
    }.bind(this)
  }
  getProductionData() {
    var production_arr;
    var pro_xhr = new XMLHttpRequest();
    pro_xhr.open('get', this.app.host + 'api/products', true);
    pro_xhr.send();
    pro_xhr.onload = function() {
      if (pro_xhr.status == 200) {
        production_arr = JSON.parse(pro_xhr.responseText);
        // console.log(production_arr);
        for (var i = 0; i < production_arr.data.length; i++) {

          if (production_arr.data[i].is_special == true) {
            // console.log(production_arr.data[i]);
            $('.production__slider').append('' +
              '<div class="production__slider__item item" data-id="' + production_arr.data[i].id + '">' +
              '<div class="item__img">' +
              '<img src="' + this.app.host + production_arr.data[i].medias[0].path + '" />' +
              '</div>' +
              '<div class="item__info">' +
              '<span class="item__info__num text-center">' + production_arr.data[i].title + '</span>' +
              '<span class="item__info__price text-center">$' + production_arr.data[i].price + '</span>' +
              '</div>' +
              '</div>'
            )
          }
        }
        pro_ready = true;
        if (news_ready == true && pro_ready == true) {
          this.init();
        } else {

        }
      } else {
        console.log('error', pro_xhr.error);
      }

      var hostAddr = this.app.host;
      $("span.item__info__num").each(function() {
        // console.log($(this));
        var maxwidth = 8; //設置最多顯示的字數
        var text = $(this).text();
        if ($(this).text().length > maxwidth) {
          $(this).text($(this).text().substring(0, maxwidth));
          $(this).html($(this).html() + "...");
        };
      })

      $('.production__slider .item').click(function(e) {
        var num;
        // console.log($(this).data('id'));
        num = "" + $(this).data("id");
        var prod_arr;
        var prod_xhr = new XMLHttpRequest();
        prod_xhr.open('get', hostAddr + 'api/products/' + num, true);
        prod_xhr.send();
        prod_xhr.onload = function() {
          if (prod_xhr.status == 200) {
            prod_arr = JSON.parse(prod_xhr.responseText);
            console.log(prod_arr);
            $('#prodModal .modal-title').html(prod_arr.data[0].title);
            var imgPath = " ";
            for (var j = 0; j < prod_arr.data[0].medias.length; j++) {
              imgPath += '<img src="' + hostAddr + prod_arr.data[0].medias[j].path + '"/>'
            }
            // console.log(imgPath);
            $('#prodModal .img__slider').html('' + imgPath);
            // $('#prodModal .img__slider').slick('setPosition');
            $('#prodModal .content').html(prod_arr.data[0].content_desc);

            $('#prodModal').modal('show')
          }
        }

      })
    }.bind(this)
    // $('#prodModal .img__slider').slick({
    //   infinite: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   prevArrow: false,
    //   nextArrow: false,
    //   dots: true
    // })
  }

  getNewsData() {
    var news_arr;
    var news_xhr = new XMLHttpRequest();
    news_xhr.open('get', this.app.host + 'api/news/index', true);
    news_xhr.send();
    news_xhr.onload = function() {
      if (news_xhr.status == 200) {
        news_arr = JSON.parse(news_xhr.responseText);

        // console.log(news_arr.data);
        for (var i = 0; i < news_arr.data.length; i++) {
          $('.news__slider').append('' +
            '<div class="news__slider__item item">' +
            '<div class="item__img">' +
            '<img src="' + this.app.host + news_arr.data[i].medias[0].path + '" />' +
            '</div>' +
            '<h5 class="item__title">' + news_arr.data[i].title + '</h5>' +
            '<div class="item__text">' +
            news_arr.data[i].short_desc +
            '</div>' +
            '<span class="item__cal">' + news_arr.data[i].publish_date + '</span>' +
            '<button class="button news__button" onclick="javascript:location.href=\'news.html\'">Read More</button>' +
            '</div>'
          )

        }
      } else {

      }
      news_ready = true;
      if (news_ready == true && pro_ready == true) {
        this.init();
      } else {

      }
    }.bind(this)
  }
  init() {
    // console.log("all ready");
    this.fontResize();
    this.setSlider();

  }
  fontResize() {
    $('.production__slider__item .item__info__num').each(
      function() {
        // console.log($(this).text().length);
        if ($(this).text().length > 10) {
          $(this).css('font-size', '0.9rem');
        }
      }
    )
    $(".news__slider .item__title").each(function() {
      // console.log($(this));
      var maxwidth = 26; //設置最多顯示的字數
      var text = $(this).text();
      if ($(this).text().length > maxwidth) {
        $(this).text($(this).text().substring(0, maxwidth));
        $(this).html($(this).html() + "...");
      };
    })
    $(".news__slider .item__text").each(function() {
      // console.log($(this));
      var maxwidth = 150; //設置最多顯示的字數
      var text = $(this).text();
      if ($(this).text().length > maxwidth) {
        $(this).text($(this).text().substring(0, maxwidth));
        $(this).html($(this).html() + "...");
      };
    })
  }
  setSlider() {
    $(".kv-slider").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
    $(".news__slider").slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      prevArrow: false,
      nextArrow: false,
      responsive: [

        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    if($('.news__slider__item').length<=8){
      console.log('hide arrow')
      $('.news .slick-prev').hide();
      $('.news .slick-next').hide();
    }else{
      console.log('show arrow')
      $('.news .slick-prev').show();
      $('.news .slick-next').show();
    }
    $('.news .slick-prev').click(function() {
      $(".news__slider").slick('slickPrev');
    });
    $('.news .slick-next').click(function() {
      $(".news__slider").slick('slickNext');
    });
    $(".production__slider").slick({
      infinite: true,
      slidesPerRow: 4,
      rows: 2,
      dots: false,
      prevArrow: false,
      nextArrow: false,
      responsive: [{
          breakpoint: 1200,
          settings: {
            slidesPerRow: 3,
            rows: 2,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesPerRow: 2,
            rows: 2,
          }
        },
        {
          breakpoint: 567,
          settings: {
            slidesPerRow: 1,
            rows: 1,
          }
        }
      ]
    });

    if($('.production__slider__item').length<=8){
      // console.log('hide arrow')
      $('.production .slick-prev').hide();
      $('.production .slick-next').hide();
    }else{
      // console.log('show arrow')
      $('.production .slick-prev').show();
      $('.production .slick-next').show();
    }
    $('.production .slick-prev').click(function() {
      $(".production__slider").slick('slickPrev');
    });
    $('.production .slick-next').click(function() {
      $(".production__slider").slick('slickNext');
    });
  }
  hashchange() {

  }
}
