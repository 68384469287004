import $ from 'jquery';

var coo_ready = false;
var albums_ready = false;
export default class Cooperation {
  constructor(app) {
    this.app = app;
    // console.log(this.app);
    this.getData();

  }
  getData() {
    var coo_arr;
    var coo_xhr = new XMLHttpRequest();
    coo_xhr.open('get', this.app.host + 'api/albums', true);
    coo_xhr.send();
    coo_xhr.onload = function() {
      if (coo_xhr.status == 200) {
        coo_arr = JSON.parse(coo_xhr.responseText);

        var obj = coo_arr.data;
        for (var x in obj) {
          $('.cal-slider').append('' +
            '<div class="cooperation-page__cal-item text-center">' +
            '<div class="cooperation-page__cal-item--link year-btn" data-year="' + x + '">' + x + '</div>' +
            '</div>'
          )
          for (var i = 0; i < obj[x].length; i++) {
            // console.log(obj[x][i]);
            var imgSlider = "";
            for (var im = 0; im < obj[x][i].medias.length; im++) {
              // console.log(obj[x][i].medias[im].path);
              imgSlider += '<img class="img__slider__img" src="' + this.app.host + obj[x][i].medias[im].path + '" alt=""/>'
            }
            $('.cooperation-page__content .row').append('' +
              '<div class="col-12 col-lg-8 offset-lg-2 albums-page__item" data-year="' + obj[x][i].year + '">' +
              '<div class="img__slider">' +
              imgSlider +
              '</div>' +
              '<h5 class="page-content__title text-center">' + obj[x][i].title + '</h5>' +
              '<p class="page-content__text text-center">' + obj[x][i].year + '</p>' +
              '<div class="item__text text-center">' + obj[x][i].short_desc + '</div>'+
              '</div>'
            )
          }
        }
        this.init();


      } else {

      }
    }.bind(this)
    //
  }

  init() {
    this.setSlider();
    this.setListener();
  }
  setListener(){
    $('.year-btn').click(function() {
      var year = $(this).data("year");
      // console.log($(this).data("year"))
      $('.cooperation-page__item, .albums-page__item').hide();
      $('.cooperation-page__item, .albums-page__item[data-year=' + year + ']').show();
      $('.img__slider').slick('setPosition');
    })
  }
  setSlider() {
    $('.img__slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: false,
      nextArrow: false,
      dots: true
    })
    $('.cal-slider').slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    })
  }
  refreshSlder() {
    $('.img__slider').slick('setPosition');
    $('.cal-slider').slick('setPosition');
  }
  hashchange(target) {
    this.refreshSlder();
  }
}
