import $ from 'jquery';

var news_ready = false;
var albums_ready = false;
var hash;
var newsId;
var news_year = [];
var albums_year = [];
var news_all = [];
var albums_all = [];

export default class News {
  constructor(app) {
    this.app = app;

    // console.log(this.app);
    hash = this.app.hash;
    // console.log(this.app.hash);
    if(hash.indexOf("sub")==-1){
      // console.log(hash)
      window.location.replace(window.location.href+"#sub1");
    }
    this.getData();
    newsId = this.app.pid;
    $('#newsModal').on('hidden.bs.modal', function (e) {
      console.log('hidden')
      var newAddr = window.location.href.replace('id='+newsId, '');
      window.location.replace(newAddr);
    })

  }

  getData() {

    var news_arr;
    var news_xhr = new XMLHttpRequest();

    if (this.app.testMode == true) {
      news_xhr.open('get', './api/news.json', true);
    } else {
      news_xhr.open('get', this.app.host + 'api/news', true);
    }

    news_xhr.send();
    news_xhr.onload = function() {
      if (news_xhr.status == 200) {
        news_arr = JSON.parse(news_xhr.responseText);
        //
        var obj = news_arr.data;

        for (var x in obj) {
          for (var i = 0; i < obj[x].length; i++) {
            if (obj[x][i].type == "1") {
              if ($.inArray(x, news_year) == -1) {
                news_year.push(x);
              }
              news_all.push(obj[x][i]);
            } else {
              if ($.inArray(x, albums_year) == -1) {
                albums_year.push(x);
              }
              albums_all.push(obj[x][i]);
            }
          }
        }
        this.init();
      } else {

      }
      this.hashchange(this.app)
    }.bind(this)

  }
  setListener() {
    $('.year-btn').click(function() {
      $('.year-btn').not(this).removeClass('is-active');
      if($(this).hasClass('is-active')){

      }else{
        var year = $(this).data("year");
        if (hash == 'sub1') {
          $('.news-page__item').hide();
          $('.news-page__item[data-year=' + year + ']').show();
          $('.news-page__item[data-year=' + year + '] .img__slider').slick('setPosition');
        } else {
          $('.albums-page__item').hide();
          $('.albums-page__item[data-year=' + year + ']').show();
          $('.albums-page__item[data-year=' + year + '] .img__slider').slick('setPosition');
        }
        $(this).addClass('is-active');
      }

    })
    $('.news-page__item .img__slider__img').click(function() {
      $('.modal__img').attr('src', $(this).attr('src'));
      $('#newsModal').modal('show');
    })

    $('.cal-slider').on('beforeChange',function(event,slick,currentSlide,nextSlide){
      if(hash == "sub1"){
        $('.news-page__cal-item--link.year-btn:not(.is-active)[data-year='+news_year[nextSlide]+']').trigger('click');
      }else if(hash == "sub2"){
        $('.albums-page__cal-item--link.year-btn:not(.is-active)[data-year='+albums_year[nextSlide]+']').trigger('click');
      }

    })
    // console.log($(this));
  }
  init() {
    hash = this.app.hash;
    this.setPage();
    this.setSlider();
    this.setListener();
  }
  setPage() {
    this.sortArr();
    this.buildItem();
  }
  buildItem() {
    //build year btns
    for (var i = 0; i < news_year.length; i++) {
      $('.news-page__cal-slider.cal-slider').append('' +
        '<div class="news-page__cal-item text-center">' +
        '<div class="news-page__cal-item--link year-btn" data-year="' + news_year[i] + '">' + news_year[i] + '</div>' +
        '</div>'
      )
    }
    for (var j = 0; j < albums_year.length; j++) {

      $('.albums-page__cal-slider.cal-slider').append('' +
        '<div class="albums-page__cal-item text-center">' +
        '<div class="albums-page__cal-item--link year-btn" data-year="' + albums_year[j] + '">' + albums_year[j] + '</div>' +
        '</div>'
      )
    }
    //build content
    for(var news_i =0 ; news_i< news_all.length;news_i++){
      var imgSlider = "";
      for (var im = 0; im < news_all[news_i].medias.length; im++) {
        // console.log(obj[x][i].medias[im].path);
        imgSlider += '<img class="img__slider__img" src="' + this.app.host + news_all[news_i].medias[im].path + '" alt=""/>'
      }
      var x = news_all[news_i].publish_date.split('/')[0];
      // console.log(x.split('/')[0]);
        $('.news-page__content').append(
          '<div class="news-page__item" data-year="' + x + '">' +
          '<a href="#sub1?id='+news_all[news_i].id+'">'+
          '<div class="row">' +
          '<div class="col-12 col-lg-3">' +
          '<div class="img__slider">' + imgSlider + '</div>' +
          '</div>' +
          '<div class="col-12 col-lg-9">' +
          '<h3 class="item__title">' + news_all[news_i].title + '</h3>' +
          '<p class="item__date">' + news_all[news_i].publish_date + '</p>' +
          '<div class="item__text">' + news_all[news_i].short_desc + '</div>' +
          '</div>' +
          '</div>' +
          '</a>'+
          '</div>'
        )
    }
    for(var album_i =0 ; album_i< albums_all.length;album_i++){
      var imgSlider = "";
      for (var iim = 0; iim < albums_all[album_i].medias.length; iim++) {
        // console.log(obj[x][i].medias[im].path);
        imgSlider += '<img class="img__slider__img" src="' + this.app.host + albums_all[album_i].medias[iim].path + '" alt=""/>'
      }
      var x = albums_all[album_i].publish_date.split('/')[0];
      // console.log(x.split('/')[0]);
        $('.albums-page__content').append(
          '<div class="albums-page__item" data-year="' + x + '">' +
          '<a href="#sub2?id='+albums_all[album_i].id+'">'+
          '<div class="row">' +
          '<div class="col-12 col-lg-3">' +
          '<div class="img__slider">' + imgSlider + '</div>' +
          '</div>' +
          '<div class="col-12 col-lg-9">' +
          '<h3 class="item__title">' + albums_all[album_i].title + '</h3>' +
          '<p class="item__date">' + albums_all[album_i].publish_date + '</p>' +
          '<div class="item__text">' + albums_all[album_i].short_desc + '</div>' +
          '</div>' +
          '</div>' +
          '</a>'+
          '</div>'
        )
    }
  }
  sortArr() {
    // console.log(news_all);
    news_year.sort(function(a, b) {
      return b - a;
    })
    albums_year.sort(function(a, b) {
      return b - a;
    })
    news_all.sort(function(a, b) {
      return new Date(b.publish_date) - new Date(a.publish_date);
    })
    albums_all.sort(function(a, b) {
      return new Date(b.publish_date) - new Date(a.publish_date);
    })
    // console.log(news_all);
  }
  setSlider() {
    $('.img__slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: false,
      nextArrow: false,
      dots: true
    })
    $('.cal-slider').slick({
      infinite: true,
      slidesToShow: 5,
      speed: 100,
      slidesToScroll: 1,
      touchMove: false,
      mobileFirst: false,
      autoplay: false,
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    })
  }
  refreshSlder() {
    $('.img__slider').slick('setPosition');
    $('.cal-slider').slick('setPosition');
    // this.setListener();
  }
  hashchange(target) {
    this.refreshSlder();
    hash = this.app.hash;
    newsId = this.app.pid;
    var mTitle;
    var mPics;
    var mDate;
    var mDes;
    if(this.app.pid!==""){
      var subPage="";
      if(hash == "sub1"){
        subPage = "news"
        for(var i =0 ;i < news_all.length;i++){
          if(news_all[i].id == this.app.pid){
            mTitle = news_all[i].title;
            mPics = news_all[i].medias;
            var imgSlider="";
            for(var p = 0 ; p< mPics.length;p++){
                imgSlider += '<img class="img__slider__img" src="' + this.app.host + news_all[i].medias[p].path + '" alt=""/>'
            }
            mDate = news_all[i].publish_date;
            mDes = news_all[i].content_desc;
          }
        }

      }else{
        subPage = "albums"
        for(var i =0 ;i < albums_all.length;i++){
          if(albums_all[i].id == this.app.pid){
            mTitle = albums_all[i].title;
            mPics = albums_all[i].medias;
            var imgSlider="";
            for(var p = 0 ; p< mPics.length;p++){
                imgSlider += '<img class="img__slider__img" src="' + this.app.host + albums_all[i].medias[p].path + '" alt=""/>'
            }
            mDate = albums_all[i].publish_date;
            mDes = albums_all[i].content_desc;
          }
        }
      }

      $('.modal-body .img__slider').html(imgSlider);
      $('#newsModal').modal('show');
      $('.modal-body .item__title').html(mTitle);
      $('.modal-body .item__date').html(mDate);
      $('.modal-body .item__text').html(mDes);
      $('.modal-body .img__slider').slick('setPosition');
    }
    // console.log(this.app.pid);
  }
}
