import stats from "stats-js";
import browser from "browser-detect";
//test fps
import $ from "jquery";
require("bootstrap");
import niceScroll from "jquery.nicescroll";
import slick from "slick-carousel";
import ScrollMagic from "scrollmagic";
import { TweenLite } from "gsap";

import News from "./pages/News";
import Production from "./pages/Production";
import IndexContent from "./pages/IndexContent";
import Cooperation from "./pages/Cooperation";

let pageName;
let hash;
let host;
let category_arr = [];
let mobileMenu = false;
var prod_count;
let mobileWidth = 1155;
// let pid;

export default class App {
  constructor() {}
  init() {
    //testMode or Online
    if (window.location.port == "8080") {
      this.testMode = true;
    } else {
      this.testMode = false;
    }
    if (this.testMode == true) {
      this.host = "./";
    } else {
      this.host = "./";
    }
    host = this.host;
    //mobile or PC
    this.testWidth();
    // this.browser = browser();
    // this.initStats();
    this.page = "";
    this.prod_sub_arr = [];
    //get Data from productions
    this.getProd();
  }
  testWidth() {
    if (window.innerWidth < mobileWidth) {
      mobileMenu = true;
    } else {
      mobileMenu = false;
    }
  }
  initContact() {
    $("input[type=checkbox]").change(function () {
      $("audio")[0].pause();
      if ($(this).prop("checked") == true) {
        switch ($(this).data("song")) {
          case 1:
            document.getElementById("audio1").play();
            break;
          case 2:
            document.getElementById("audio2").play();

            break;
          case 3:
            document.getElementById("audio3").play();
            break;
        }
      } else {
        switch ($(this).data("song")) {
          case 1:
            $("#audio1")[0].pause();
            break;
          case 2:
            $("#audio2")[0].pause();

            break;
          case 3:
            $("#audio3")[0].pause();
            break;
        }
      }
    });
    $(".mp3__lyrics").niceScroll(".wrap", {
      cursorcolor: "#0060af",
      autohidemode: false,
    });

    $("#ascrail2000").show();
    $(".contact .button").click(function () {
      var sName = $(".contact__name").val();
      var sTel = $(".contact__tel").val();
      var sEmail = $(".contact__email").val();
      var sType = $(".contact__select").val();
      var sContent = $(".contact__question").val();
      var sJson = {
        name: sName,
        phone: sTel,
        email: sEmail,
        subject: sType,
        content: sContent,
      };
      // console.log(sJson);
      $.ajax({
        type: "post",
        async: false,
        dataType: "json",
        url: host + "api/contactus/new",
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        data: sJson,
        success: function (msg) {
          // console.log('gj', msg)
          //do something
        },
        statusCode: {
          200: function (su) {
            // console.log(su)
            alert("已完成送出");
          },
          400: function (response) {
            // console.log(response);
            var errorMsg = JSON.parse(response.responseText);
            // console.log(errorMsg.msg);
            var msg = "";
            var obj = errorMsg.msg;
            for (var x in obj) {
              msg += obj[x];
            }
            // console.log(msg);
            alert("error:" + msg);
          },
        },
      });
    });
  }
  getProd() {
    var nPid_arr;
    var nPid_xhr = new XMLHttpRequest();
    if (this.testMode == true) {
      nPid_xhr.open("get", "./testapi/products.json", true);
    } else {
      nPid_xhr.open("get", this.host + "api/products", true);
    }
    nPid_xhr.send();
    nPid_xhr.onload = function () {
      if (nPid_xhr.status == 200) {
        nPid_arr = JSON.parse(nPid_xhr.responseText);
        for (var i = 0; i < nPid_arr.data.length; i++) {
          if ($.inArray(nPid_arr.data[i].category, category_arr) == -1) {
            category_arr.push(nPid_arr.data[i].category);
          }
        }
      } else {
      }
      this.buildNav();
    }.bind(this);
  }
  buildNav() {
    var nav_xhr = new XMLHttpRequest();
    if (this.testMode == true) {
      nav_xhr.open("get", "./testapi/categorys.json", true);
    } else {
      nav_xhr.open("get", this.host + "api/categorys", true);
    }
    var prod_sub = [];
    nav_xhr.send();
    nav_xhr.onload = function () {
      if (nav_xhr.status == 200) {
        this.nav_arr = JSON.parse(nav_xhr.responseText);
        var subNavArr = "";
        prod_count = 0;
        for (var i = 0; i < this.nav_arr.data.length; i++) {
          if ($.inArray("" + this.nav_arr.data[i].id, category_arr) !== -1) {
            subNavArr +=
              '<li class="sub__nav__item"><a class="suba" href="production.html#sub' +
              this.nav_arr.data[i].id +
              '">' +
              this.nav_arr.data[i].name +
              "</a></li>";
            prod_sub.push(this.nav_arr.data[i].id);
            prod_count++;
          }
        }

        this.prod_sub = prod_sub;
        $("header").append(
          "" +
            '<nav class="nav">' +
            '<div class="nav__container">' +
            '<div class="nav__submenu is-active"></div>' +
            '<a href="index.html" class="nav__logo"><img src="assets/images/logo.png" /></a>' +
            '<ul class="nav__content">' +
            '<li class="nav__item"><a class="" href="index.html">首頁</a></li>' +
            '<li class="nav__item">' +
            '<a class="nav__item__link link__about" href="javascript:void(0);">關於丘比</a>' +
            '<ul class="sub__nav__content">' +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="about.html">關於丘比</a>' +
            "</li>" +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="about.html#sub1">品牌源起</a>' +
            "</li>" +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="about.html#sub2">角色介紹</a>' +
            "</li>" +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="about.html#sub3">丘比之歌</a>' +
            "</li>" +
            "</ul>" +
            "</li>" +
            '<li class="nav__item">' +
            '<a class="nav__item__link link__news" href="javascript:void(0);">最新消息</a>' +
            '<ul class="sub__nav__content">' +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="news.html#sub1">消息列表</a>' +
            "</li>" +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="news.html#sub2">媒體報導</a>' +
            "</li>" +
            "</ul>" +
            "</li>" +
            '<li class="nav__item nav__prod">' +
            '<a class="nav__item__link link__production"  href="javascript:void(0);">產品總覽</a>' +
            '<ul class="sub__nav__content">' +
            subNavArr +
            "</ul>" +
            "</li>" +
            '<li class="nav__item">' +
            '<a class="nav__item__link link__cooperation" href="javascript:void(0);">授權合作</a>' +
            '<ul class="sub__nav__content">' +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="cooperation.html#sub1">授權聯名</a>' +
            "</li>" +
            '<li class="sub__nav__item">' +
            '<a class="suba" href="cooperation.html#sub2">授權案例</a>' +
            "</li>" +
            "</ul>" +
            "</li>" +
            '<li class="nav__item"><a href="contact.html">聯絡我們</a></li>' +
            '<li class="nav__lang"><a href="//en.kewpie-love.com.tw" target="_self" class="nav__lang--link">EN</a><span class="nav__lang--active">繁體</span><a href="//cn.kewpie-love.com.tw" target="_self" class="nav__lang--link">簡體</a></li>' +
            "</ul>" +
            '<a href="https://shopee.tw/s84045eddie?smtt=0.0.9" target="_blank" class="nav__shop">丘比の部屋<i><img src="assets/images/icon_shop.jpg" /></i></a>' +
            '<a class="hamburger"><span></span><span></span><span></span></a>' +
            "</div>" +
            "</nav>"
        );
        this.initPages();
        this.addListeners();
        this.animate();
        this.initSlider();
        this.initContact();

        this.initNav();
      }
    }.bind(this);
  }
  initPages() {
    this.getHash();
    this.showPage();
  }
  initNav() {
    $(".nav__submenu").removeClass("is-active");
    if (mobileMenu == false) {
      // pc menu
      $(".nav__item").hover(
        function () {
          //hover function
          if ($(".nav__item").hasClass("is-active")) {
            $(".nav__item").removeClass("is-active");
            $(".nav__submenu").removeClass("is-active");
          }
          $(this).addClass("is-active");
          // console.log();
          if ($(this).children("ul.sub__nav__content").length !== 0) {
            $(".nav__submenu").addClass("is-active");
          }
        },
        function () {
          //out function
          $(".nav__item").removeClass("is-active");
          $(".nav__submenu").removeClass("is-active");
          //
          $(".nav__item").each(function () {
            var tempStr = $(this).children("a").attr("href");
            if (tempStr == pageName) {
              $(this).addClass("is-active");
              if ($(this).children("ul.sub__nav__content").length !== 0) {
                $(".nav__submenu").addClass("is-active");
              }
            }
          });
        }
      );
    } else {
      //mobile menu
      $(".nav__item").click(function () {
        if ($(".nav__item").hasClass("is-active")) {
          $(".nav__item").removeClass("is-active");
          $(".nav__submenu").removeClass("is-active");
        }
        $(this).addClass("is-active");
        if ($(this).children("ul.sub__nav__content").length !== 0) {
          $(".nav__submenu").addClass("is-active");
        }
      });
    }

    $(".nav__item").each(function () {
      var tempStr = $(this).children("a").attr("href");
      if (tempStr == pageName) {
        $(this).addClass("is-active");
        if ($(this).children("ul.sub__nav__content").length !== 0) {
          $(".nav__submenu").addClass("is-active");
        }
      }
    });
    $(".hamburger").click(function () {
      if ($(".hamburger").hasClass("is-active")) {
        $(".hamburger").removeClass("is-active");
        $(".nav__content").removeClass("is-active");
        $(".nav__shop").removeClass("is-active");
      } else {
        $(".hamburger").addClass("is-active");
        $(".nav__content").addClass("is-active");
        $(".nav__shop").addClass("is-active");
      }
    });
    $(
      'nav .nav__item a:not(.nav__item__link[href^="javascript:void(0)"])'
    ).click(function () {
      $(".hamburger").trigger("click");
    });
  }
  initSlider() {

    if($(".nav__prod .sub__nav__content").hasClass('slick-initialized')){
      $(".nav__prod .sub__nav__content").slick('unslick');
    }
    if (
      (prod_count+2) * 110 > window.innerWidth &&
      window.innerWidth >= mobileWidth
    ) {

      $(".nav__prod .sub__nav__content").slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: false,
        dots: false,
      });
    }
  }
  //init Stats
  initStats() {
    this.stats = new stats();
    this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    document.body.appendChild(this.stats.dom);
  }

  addListeners() {
    this.handlerAnimate = this.animate.bind(this);
    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener("keyup", this.keyup.bind(this));
    window.addEventListener("mousemove", this.mousemove.bind(this));
    window.addEventListener("click", this.click.bind(this));
    window.addEventListener("mouseup", this.mouseup.bind(this));
    window.addEventListener("touchend", this.mouseup.bind(this));
    window.addEventListener("hashchange", this.funcRef.bind(this), false);

    this.scrollEvt();
  }
  scrollEvt() {
    $("#kewpish").scroll(function () {
      if ($(".app_wrapper").offset().top < -window.innerHeight / 2) {
        $(".scroll-to-top").show();
      } else {
        $(".scroll-to-top").hide();
      }
    });

    $(".scroll-to-top").click(
      function (evt) {
        this.scrolltoTop();
      }.bind(this)
    );
  }
  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------
  animate() {
    if (this.stats) this.stats.begin();
    this.update();
    if (this.stats) this.stats.end();
    this.raf = requestAnimationFrame(this.handlerAnimate);
  }
  update() {}

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------
  //網址改變
  getHash() {
    this.pid = "";
    if (window.location.hash.substr(1).indexOf("?") != -1) {
      var ary1 = window.location.hash.substr(1).split("?");
      if (hash !== ary1[0]) {
        hash = ary1[0];
        this.scrolltoTop();
      }
      var ary2 = ary1[1].split("&");
      var ary3 = ary2[0].split("=");
      if (ary3[0] == "id") {
        this.pid = ary3[1];
      }
    } else {
      if (hash !== window.location.hash.substr(1)) {
        hash = window.location.hash.substr(1);
        this.scrolltoTop();
      }
    }
    this.hash = hash;

    var path = window.location.pathname;
    if (pageName == path.split("/").pop()) {
    } else {
      pageName = path.split("/").pop();
      //
      switch (pageName) {
        case "":
          this.indexContent = new IndexContent(this);
          break;
        case "index.html":
          this.indexContent = new IndexContent(this);
          break;
        case "news.html":
          this.news = new News(this);
          break;
        case "production.html":
          this.production = new Production(this);
          break;
        case "cooperation.html":
          this.cooperation = new Cooperation(this);
          break;
      }
    }
  }
  funcRef() {
    this.getHash();
    this.showPage();
    if (this.news) this.news.hashchange(hash);
    if (this.production) this.production.hashchange(hash);
    if (this.cooperation) this.cooperation.hashchange(hash);
  }
  showPage() {
    if (hash != "") {
      // console.log(hash);
      $(".page-content").hide();
      $(".page-content." + hash).show();
      $(".sub__nav__item a").each(function () {
        var nowHref = $(this).attr("href").split("#")[1];
        var nowPage = $(this).attr("href").split("#")[0];
        // console.log('link',nowPage);
        // console.log('pageName',pageName);
        // console.log(nowHref,"nowHref");
        if (nowHref == hash && nowPage == pageName) {
          $(this).css("color", "#00b3e4");
        } else {
          $(this).css("color", "#FFFFFF");
        }
      });
    } else {
      $(".page-content").hide();
      $(".page-content.index").show();

      $('.sub__nav__item a[href="about.html"]').each(function () {
        if (pageName === "about.html") {
          $(this).css("color", "#00b3e4");
        } else {
          $(this).css("color", "#FFF");
        }
      });
    }
  }
  scrolltoTop() {
    var $body = $("#kewpish");
    $body.animate(
      {
        scrollTop: 0,
      },
      600
    );
  }
  resetMenu() {
    this.initNav();
    this.initSlider();
  }
  resize() {
    this.testWidth();
    this.resetMenu();
    if(this.production){
      this.production.resizing();
    }
    
  }

  keyup(e) {}
  mouseup(e) {}
  mousemove(e) {}

  click(e) {}
}
